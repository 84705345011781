/* eslint-disable react-hooks/exhaustive-deps */

import "./styles.scss"

import React, { useState, useEffect } from "react"
import Slider from "react-slick"
import Lightbox from "yet-another-react-lightbox"
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen"
import Zoom from "yet-another-react-lightbox/plugins/zoom"

import FavoriteAction from "components/FavoriteAction"
import ProductBuy from "components/ProductBuy"

import IcoInfo from "assets/icons/info.svg"

const ContentSingle = ({ data }) => {
  const isBrowser = typeof window !== "undefined"
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isLightboxOpen, setIsLightboxOpen] = useState(false)
  const [gallery, setGallery] = useState([
    data?.featuredImage?.node?.localFile?.publicURL,
  ])

  useEffect(() => {
    data?.acfProduct?.galleryInside &&
      setGallery([
        gallery,
        ...data?.acfProduct?.galleryInside?.map(
          item => item.localFile.publicURL
        ),
      ])
  }, [])

  const slider = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 750,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    pauseOnHover: false,
  }

  return (
    <section className="outlet-single">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-7">
            <div className="wallpaper-single__left-content">
              <div className="wallpaper-single__gallery">
                <Slider {...slider}>
                  {gallery?.map((item, index) => (
                    <div key={index}>
                      <div
                        className="wallpaper-single__gallery-item"
                        style={{
                          backgroundImage: `url('${item}')`,
                        }}
                      >
                        {isBrowser && (
                          <button
                            className="wallpaper-single__enlarge"
                            onClick={e => {
                              e.preventDefault()
                              setIsLightboxOpen(true)
                              setPhotoIndex(index)
                            }}
                          >
                            Enlarge
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </Slider>
                <div
                  className={`outlet-single__image-label ${data?.productCategories?.nodes[0]?.name
                    ?.toLowerCase()
                    .replace("ś", "s")}`}
                >
                  {data?.productCategories?.nodes[0]?.name}
                </div>
                {data?.id && <FavoriteAction id={data?.id} />}
              </div>
            </div>
          </div>

          <div className="col-md-5">
            <h2>{data?.name}</h2>
            <p className="outlet-single__collection">
              {data?.acfProduct?.collection}
            </p>

            <div className="outlet-single__info">
              <h4>Informacje</h4>
              <div dangerouslySetInnerHTML={{ __html: data?.content }} />
            </div>

            <div className="outlet-single__icons">
              <h4>Właściwości tapety</h4>
              <div className="outlet-single__icons-wrapper">
                {data?.acfProduct?.iconListing?.map((item, index) => (
                  <div key={index}>
                    <span>{item?.description}</span>
                    <img
                      src={item?.icon?.localFile?.publicURL}
                      alt={item?.description}
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className="outlet-single__list">
              {data?.acfProduct?.outletSpecification?.map((item, index) => (
                <div className="outlet-single__list-item" key={index}>
                  <strong>{item?.label}</strong>
                  <div>
                    <p>{item?.value || 0}</p>
                    {item?.linkUrl && (
                      <a href={item?.linkUrl} traget="_blank">
                        {item?.linkText}
                      </a>
                    )}
                    {item?.info && (
                      <button>
                        <img src={IcoInfo} alt="" />
                        <span>{item?.info}</span>
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>

            <ProductBuy
              id={data?.id}
              databaseId={data?.databaseId}
              name={data?.name}
              price={data?.price}
              product={data}
              singleQuantity
              outlet
            />
          </div>
        </div>

        {/* {data?.acfProduct?.galleryInside && (
          <div className="wallpaper-single__gallery mobile">
            <Slider {...slider}>
              {data?.acfProduct?.galleryInside?.map((item, index) => (
                <div key={index}>
                  <div
                    className="wallpaper-single__gallery-item"
                    style={{
                      backgroundImage: `url('${item?.localFile?.publicURL}')`,
                    }}
                  >
                    <button
                      onClick={e => {
                        e.preventDefault()
                        setIsLightboxOpen(true)
                        setPhotoIndex(index)
                      }}
                    >
                      Enlarge
                    </button>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        )} */}
      </div>

      <Lightbox
        open={isLightboxOpen}
        index={photoIndex}
        close={() => setIsLightboxOpen(false)}
        plugins={[Fullscreen, Zoom]}
        slides={gallery?.map(image => ({
          src: image,
        }))}
      />
    </section>
  )
}

export default ContentSingle
